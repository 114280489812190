import { Component, OnInit, OnDestroy, ViewChild } from "@angular/core";
import { TwilioConnection } from "src/app/twilio-connection/twilio-connection";
import { Router, RouterModule } from "@angular/router";
import { LocalServiceService } from "src/app/services/local-service.service";
import { ApisService } from "src/app/services/apis.service";
import { MatDialog, MatDialogRef } from "@angular/material/dialog";
import { CommonModule } from "@angular/common";
import { FormsModule } from "@angular/forms";
import { MatMenuModule, MatMenuTrigger } from "@angular/material/menu";
import { HttpClientModule, HttpErrorResponse } from "@angular/common/http";
import { CookieService } from "ngx-cookie-service";
import { Location } from "@angular/common";
import { getUserStatus, twilioDetails } from "../patients/patients-interface";
import { ToastrService } from "ngx-toastr";
import { InternetConnectivityModalComponent } from "../inbox/internet-connectivity-modal/internet-connectivity-modal.component";
import { Overlay } from "@angular/cdk/overlay";
import { InboxService } from "../inbox/inbox.service";
import { uiClientsConfig, reservedCodeCategories } from 'config';

import { v4 } from "uuid";
import { customWindow, permission } from "src/custom";
import { activities, getCodeCategory, getUserProfileDetail, getWorkspaceActivities, NavbarMatMenuDefaultStates, oauthUserChannel, OauthUserChannelAction, twilioWorker } from "./interface";
import { Subscription } from "rxjs";

@Component({
  standalone: true,
  imports: [CommonModule, FormsModule, HttpClientModule, MatMenuModule, RouterModule],
  selector: "app-navbar",
  templateUrl: "./navbar.component.html",
  styleUrls: ["./navbar.component.css"],
})
export class NavbarComponent implements OnInit, OnDestroy {

  @ViewChild(MatMenuTrigger) matMenuTrigger: MatMenuTrigger = {} as MatMenuTrigger;

  constructor(
    private apiService: ApisService,
    private cookieService: CookieService,
    private dialog: MatDialog,
    public inboxService: InboxService,
    private localService: LocalServiceService,
    private location: Location,
    private overlay: Overlay,
    private router: Router,
    private toastr: ToastrService,
    private twilioConnection: TwilioConnection) { }

  window: customWindow = window;
  getUserProfileDetail: Subscription = new Subscription();
  getCodeCategory: Subscription = new Subscription();
  getWorkspaceActivities: Subscription = new Subscription();
  getValidPermission: Subscription = new Subscription();

  navbarMatMenuDefaultStates: NavbarMatMenuDefaultStates = {
    "AFTER_CALL": {
      userStatus: "After Call",
      userStatusColor: "RED"
    },
    "GETTING_READY": {
      userStatus: "Getting Ready",
      userStatusColor: "#7D7F82"
    },
    "DEFAULT": {
      userStatus: "Getting Ready",
      userStatusColor: "#7D7F82"
    },
    "DEFAULT_SUCCESS": {
      userStatus: "Taking Calls",
      userStatusColor: "#046c04"
    },
    "MAKING_CALLS": {
      userStatus: "Making Calls",
      userStatusColor: "#046c04"
    },
    "ON_CALL": {
      userStatus: "On Call",
      userStatusColor: "#14d7ce"
    }
  };

  oauthUserChannelActionMap: { [key: string]: OauthUserChannelAction } = {
    "RESERVATION_ENDED": {
      userStatus: this.navbarMatMenuDefaultStates["AFTER_CALL"].userStatus,
      userStatusColor: this.navbarMatMenuDefaultStates["AFTER_CALL"].userStatusColor,
      isMatMenuAllowedToOpen: false,
      isMatMenuValueBeingChanged: true,
      createOauthUserChannelAndUpdateWorkerStatus: ["AFTER_CALL", false, false]
    },
    "RESERVATION_ACCEPTED": {
      userStatus: this.navbarMatMenuDefaultStates["ON_CALL"].userStatus,
      userStatusColor: this.navbarMatMenuDefaultStates["ON_CALL"].userStatusColor,
      isMatMenuAllowedToOpen: false,
      isMatMenuValueBeingChanged: true,
      createOauthUserChannelAndUpdateWorkerStatus: ["ON_CALL", false, false]
    },
    "WORKER_READY": {
      userStatusColor: this.navbarMatMenuDefaultStates["DEFAULT_SUCCESS"].userStatusColor,
      isMatMenuAllowedToOpen: true
    },
    "ACTIVITY_RESET": {
      userStatus: this.navbarMatMenuDefaultStates["DEFAULT_SUCCESS"].userStatus,
      userStatusColor: this.navbarMatMenuDefaultStates["DEFAULT_SUCCESS"].userStatusColor,
      isMatMenuAllowedToOpen: true,
      createOauthUserChannelAndUpdateWorkerStatus: ["TWILIO_CALLS", true, false],
      isMatMenuValueBeingChanged: true,
    },
    "ACTIVITY_RESET_MAKING_CALLS": {
      userStatus: this.navbarMatMenuDefaultStates["MAKING_CALLS"].userStatus,
      userStatusColor: this.navbarMatMenuDefaultStates["MAKING_CALLS"].userStatusColor,
      isMatMenuAllowedToOpen: true,
      createOauthUserChannelAndUpdateWorkerStatus: ["MAKING_CALLS", false, true],
      isMatMenuValueBeingChanged: true,
    },
    "RESERVATION_CREATED": {
      isMatMenuAllowedToOpen: false,
    },
    "RESERVATION_TIMEOUT": {
      isMatMenuAllowedToOpen: true,
      isMatMenuValueBeingChanged: true,
    },
    "RESERVATION_CANCELED": {
      isMatMenuAllowedToOpen: true,
    },
    "WORKER_OFFLINE": {
      userStatusColor: this.navbarMatMenuDefaultStates["DEFAULT"].userStatusColor,
    }
  };

  isReloading: boolean = false;
  isMatMenuAllowedToOpen: boolean = true;
  appsRedirectUrl = "https://" + uiClientsConfig.customerUrlPrefix + "-admin." + uiClientsConfig.reservedUrlDomain + "/v1/apps";
  appsRedirectUrl1 = "https://" + uiClientsConfig.customerUrlPrefix + "-admin." + uiClientsConfig.reservedUrlDomain;
  appsRedirectURl2 = "https://" + uiClientsConfig.customerUrlPrefix + "-focus." + uiClientsConfig.reservedUrlDomain;
  imgUrlPrefix = "https://" + uiClientsConfig.customerUrlPrefix + "-admin." + uiClientsConfig.reservedUrlDomain;
  oauthUserName: string = "";
  oauthEmailAddress: string = "";
  deleteOauthUser: string = "";
  twilioWorker: twilioWorker = {} as twilioWorker;
  oauthUserChannel: oauthUserChannel = {} as oauthUserChannel;
  isTwilioWorkerEnabled: boolean = false;
  orgEntityId: string = "";
  oauthUserProfilePictureUrl: string = "";
  clientId: string = uiClientsConfig.AdminClientId;
  customerUrlPrefix: string = window.location.hostname.split("-")[0];
  reservedCodeValueTwilioCallsChannelId = reservedCodeCategories.reservedCodeValueTwilioCallsChannelId;
  reservedCodeCategoryOauthUserChannelId = reservedCodeCategories.reservedCodeCategoryOauthUserChannelId
  searchString: string = "";
  isNavbarComponentLoaded: boolean = false;
  isCall: boolean = false;
  isEmail: boolean = false;
  isMessaging: boolean = false;
  isTask: boolean = false;
  isSocial: boolean = false;
  oauthUserEmail: string = "";
  redirectURI: string = "";
  getUserStatus: getUserStatus = {
    channelId: "",
    channel: "",
    userStatus: "",
    sessionId: "",
    oauthUserId: "",
    twilioWorkerId: "",
    twilioWorkerActivityId: "",
    twilioWorkerActivityName: "",
  }
  twilioDetails: twilioDetails = {
    device: "",
    worker: "",
    workerId: "",
    workerName: ""
  }
  twilioWorkspaceActivities: activities[] = [];
  userStatus: string | undefined = "Getting Ready";
  userStatusColor: string = "";
  dialogRef: MatDialogRef<InternetConnectivityModalComponent, undefined> = {} as MatDialogRef<InternetConnectivityModalComponent, undefined>;
  reloadTimer: NodeJS.Timeout = {} as NodeJS.Timeout;
  postOauthUserChannel: Subscription = new Subscription();

  allPermission: permission = {
    "GetPatientDetails": false,
    "InboxFocusGet": false,
    "FocusDashboardGet": false,
    "FocusReportsGet": false,
    "OutboundDailpadGet": false,
    "PostPatientFileUpload": false
  };

  allSubPermission: permission = {
    "FocusDashboardLiveVoice": false,
    "FocusDashboardDigitalEngagement": false,
    "FocusDashboardRealtime": false
  };

  defaultDashboardRoute: string = "";
  status: string = "";

  searchPatient() {
    this.localService.changeMessage(this.searchString);
    this.router.navigate(["focus/patients"]);
    this.searchString = "";
  }
  searchInput() {
    this.searchString = this.searchString.trim();
  }

  activeIndex: number = 0;
  setActiveIndex(index: number) {
    this.activeIndex = index;
  }

  isLinkActive(url: string): boolean {
    const queryParamsIndex = this.router.url.indexOf("?");
    const baseUrl = queryParamsIndex === -1 ? this.router.url : this.router.url.slice(0, queryParamsIndex);
    return baseUrl === url;
  }

  deleteCookie(name: string): void {
    document.cookie = name + "=;expires=Thu, 01 Jan 1970 00:00:01 GMT;path=/;domain=." + uiClientsConfig.reservedUrlDomain + ";";
  };

  async logout(): Promise<void> {
    var customerUrlPrefix = window.location.hostname.split("-")[0];
    customerUrlPrefix = customerUrlPrefix + "-admin";
    this.redirectURI = this.location.path(true);
    let exactRedirectURI: string = this.appsRedirectURl2 + this.redirectURI;
    let isLoggedIn: boolean = false;
    let isLoginPageLoading: boolean = true;

    try {
      this.deleteOauthUserChannelDetails();
      isLoggedIn = false;
      isLoginPageLoading = false;
      this.deleteCookie(customerUrlPrefix);
      localStorage.clear();
      sessionStorage.clear();
      this.cookieService.deleteAll();
      var authUrl = this.appsRedirectUrl1 + "/auth?redirecturl=" + encodeURIComponent(exactRedirectURI) + "&client_id=" + this.clientId;
      window.location.href = authUrl;
    } catch (error) {
      console.error("Error logging out", error);
      // Handle errors here, e.g. display an error message to the user
    }
  }

  setSessionUserChannelAvailability = () => {
    let userStatus: string = "GETTING_READY";
    if (this.oauthUserChannel?.id) {
      userStatus = this.oauthUserChannel.userStatus;
    }
    if (window.localStorage.getItem("internetReloadStatus")) {
      userStatus = window.localStorage.getItem("internetReloadStatus") || "";
      window.localStorage.removeItem("internetReloadStatus");
    }
    this.providerChannelAvailability(userStatus, "");
  }

  getSessionId() {
    let session = sessionStorage.getItem("sessionId") || "";
    if (!session) {
      session = v4();
      sessionStorage.setItem("sessionId", session);
    }
    return session;
  }

  createOauthUserChannelAndUpdateWorkerStatus(checkBoxStatus: string, isTwilioCalls: boolean = false, isTwilioOutboundCalls: boolean = false) {
    this.getUserStatus.channel = checkBoxStatus;
    this.getUserStatus.userStatus = checkBoxStatus;
    this.getUserStatus.sessionId = this.getSessionId();
    this.getUserStatus.oauthUserId = this.cookieService.get(this.customerUrlPrefix + "_providerUserId");
    if (isTwilioCalls || isTwilioOutboundCalls) {
      this.initializeInternalSocketConnection(isTwilioOutboundCalls);
    }
    this.localService.sendUserStatus(this.getUserStatus["userStatus"])
    if (isTwilioOutboundCalls &&
      this.getUserStatus?.userStatus.toUpperCase() === "ON_CALL") {
      this.getUserStatus.userStatus = this.getUserStatus.channel = this.getUserStatus.channel + "_OUTBOUND";
    }
    this.postOauthUserChannel = this.apiService.postOauthUserChannel(this.getUserStatus).subscribe();
  }

  initializeInternalSocketConnection(isTwilioOutboundCalls: boolean) {
    const availableActivity: activities = this.twilioWorkspaceActivities.find((activity: activities) => activity.available && (activity.friendlyName).toUpperCase() === "AVAILABLE") || {} as activities;
    const makingCallsActivity: activities = this.twilioWorkspaceActivities.find((activity: activities) => activity.available && (activity.friendlyName).toUpperCase() === "MAKING_CALLS") || {} as activities;
    const activity: activities = isTwilioOutboundCalls ? makingCallsActivity : availableActivity;
    if (this.twilioWorker.id) {
      this.getUserStatus.twilioWorkerId = this.twilioWorker.id;
      this.getUserStatus.twilioWorkerActivityId = activity.id;
      this.getUserStatus.twilioWorkerActivityName = activity.friendlyName;
      if (!this.window["microServiceTwilioSocketConnection"] ||
        !this.window["microServiceTwilioSocketConnection"]?.connected ||
        !this.window["microServiceTwilioSocketConnection"]?.active) {
        console.log("Before internal twilio microservice connection");
        this.localService.initiateTwilioSocketConnection(this.getUserStatus.oauthUserId);
      }
    }
  }

  deleteOauthUserChannelDetails() {
    if (this.twilioWorkspaceActivities && this.twilioWorker?.id) {
      const unAvailableActivity: activities = this.twilioWorkspaceActivities.find((activity: activities) => !activity.available && (activity.friendlyName).toUpperCase() === "OFFLINE") || {} as activities;
      this.apiService.deleteOauthUser(this.deleteOauthUser, {
        channel: "TWILIO_CALLS",
        twilioWorkerId: this.twilioWorker.id,
        twilioWorkerActivityId: unAvailableActivity?.id,
        twilioWorkerActivityName: unAvailableActivity?.friendlyName
      });
    }
  }

  private async createTwilioDeviceAndWorkerAssociations(isTwilioOutboundCalls: boolean) {
    if (this.twilioWorker?.id) {
      this.twilioConnection.connectTwilioWorker(this.twilioWorker.id, this.twilioWorkspaceActivities, isTwilioOutboundCalls);
      if (!this.window["twilioDeviceConnected"]) {
        this.twilioConnection.connectTwilioDevice(this.twilioWorker.workerName);
      }
    }
  }

  onEvent(event: Event) {
    event.stopPropagation();
  }

  /**
   *
   * @param initialValue
   * @returns
   */
  matMenuValueTrackerClass = (initialValue: string) => {
    let that = this;
    let currentValue = String(initialValue);
    let constantValue = String(initialValue);

    function setValue(newValue: string) {
      currentValue = String(newValue);
    }

    function getCurrentValue() {
      return currentValue;
    }

    function getConstantValue(): string {
      return constantValue;
    }

    function reset(newConstantValue: string) {
      currentValue = String(newConstantValue);
      constantValue = String(newConstantValue);
    }

    function resetMatMenuAndChannelLog() {
      that.userStatus = "Taking Calls";
      that.createOauthUserChannelAndUpdateWorkerStatus("TWILIO_CALLS", true, false);
    }

    return {
      setValue,
      getCurrentValue,
      getConstantValue,
      reset,
      resetMatMenuAndChannelLog
    };
  };
  /**
   *
   * @param checkBoxStatus
   */
  providerChannelAvailability = async (checkBoxStatus: string, previousStatus: string) => {
    this.window["checkBoxStatus"] = checkBoxStatus;
    let isTwilioCalls = false;
    let isTwilioOutboundCalls = false;
    if (checkBoxStatus === "TWILIO_CALLS") {
      this.inboxService.setOpenConference(false);
    }
    if (checkBoxStatus === "TWILIO_CALLS" ||
      checkBoxStatus === "TAKING_CALLS" ||
      checkBoxStatus === "MAKING_CALLS") {
      if (checkBoxStatus === "MAKING_CALLS") {
        isTwilioOutboundCalls = true;
      } else {
        isTwilioCalls = true;
        checkBoxStatus = "TAKING_CALLS";
      }
    }
    this.userStatus = (checkBoxStatus.replace("_", " ")).toLowerCase();
    if(this.userStatus.includes("_others")){
      this.userStatus = this.userStatus.replace("_others", "")
    }
    else if(this.userStatus.includes("others")){
      this.userStatus = this.userStatus.replace("others", "")
    }
    switch (checkBoxStatus) {
      case "TWILIO_CALLS":
      case "TAKING_CALLS":
      case "MAKING_CALLS":
        this.microphonePermissions((hasValidMicrophonePermission: boolean) => {
          if (hasValidMicrophonePermission) {
            this.twilioConnection.fetchPendingReservations(({ hasPendingReservations }: { hasPendingReservations: boolean }) => {
              if (!hasPendingReservations) {
                this.createOauthUserChannelAndUpdateWorkerStatus(isTwilioCalls ? "TWILIO_CALLS" : "MAKING_CALLS", isTwilioCalls, isTwilioOutboundCalls);
                this.createTwilioDeviceAndWorkerAssociations(isTwilioOutboundCalls);
              }
              else {
                this.toastr.info("You've a pending reservation please complete that first.", "", {
                  closeButton: true
                });
                this.userStatus = previousStatus;
              }
            });
          } else {
            this.userStatus = previousStatus;
          }
        })
        break;

      case "GETTING_READY":
        this.window["isMicroServiceSocketConnected"] = false;
        this.createOauthUserChannelAndUpdateWorkerStatus(checkBoxStatus, isTwilioCalls);
        break;

      case "PERSONAL":
      case "LUNCH":
      case "BREAK":
      case "TRAINING":
      case "RECEIVING_ASSISTANCE_OTHERS":
      case "GIVING_ASSISTANCE_OTHERS":
      case "TASKING_OTHERS":
      case "MEETING":
        this.window["isMicroServiceSocketConnected"] = false;
        this.isMatMenuAllowedToOpen = true;
        this.userStatusColor = this.navbarMatMenuDefaultStates["DEFAULT"].userStatusColor;
        this.updateWorkerStatusToOffline();
        this.createOauthUserChannelAndUpdateWorkerStatus(checkBoxStatus, isTwilioCalls);
        this.twilioConnection?.disconnectAssociatedTwilioDevice();
        break;

      default:
        this.window["isMicroServiceSocketConnected"] = false;
        this.twilioConnection?.disconnectAssociatedTwilioDevice();
        this.deleteOauthUserChannelDetails();
        break;
    }
  }

  private microphonePermissions(callback: CallableFunction) {
    /**
 *
 */
    var requestMicrophonePermissions = () => {
      if (navigator.mediaDevices && navigator.mediaDevices.getUserMedia) {
        if (isChrome()) {
          this.toastr.info("Please allow microphone permissions", "", {
            closeButton: true
          });
        }
        navigator.mediaDevices.getUserMedia({ audio: true })
          .then(stream => {
            callback(true);
            if (isChrome()) {
              this.toastr.success("Microphone permissions granted", "", {
                closeButton: true
              });
            }
            console.log("Microphone permissions granted.");
            stream.getTracks().forEach(track => track.stop());
          })
          .catch(error => {
            callback(false);
            this.toastr.warning("Please allow microphone permissions, by going into your browser permissions.", "", {
              closeButton: true
            });
            console.log("Microphone permissions denied or not granted yet.");
          });
      } else {
        console.log("getUserMedia() is not supported.");
      }
    }

    if (navigator.permissions && navigator.permissions.query) {
      let queryName: string = "microphone";
      if (!isChrome()) {
        queryName = "push";
      }
      // @ts-ignore: Type ""microphone"" is not assignable to type "PermissionName".
      navigator.permissions.query({ name: queryName })
        .then((permissionStatus: PermissionStatus) => {
          if (permissionStatus.state === "granted") {
            console.log("Microphone permissions already granted.");
            callback(true);
          } else if (permissionStatus.state === "prompt") {
            console.log("Microphone permissions prompt.");
            requestMicrophonePermissions();
          } else {
            this.toastr.warning("Please allow microphone permissions, by going into your browser permissions.", "", {
              closeButton: true
            });
            callback(false);
            console.log("Microphone permissions denied.");
          }
        })
        .catch(error => {
          callback(false);
          console.log(`Error checking microphone permissions: ${error}`);
        });
    } else {
      requestMicrophonePermissions();
    }

    function isChrome() {
      let userAgent = navigator.userAgent.toLowerCase();
      if ((/chrome|crios/i).test(userAgent)) {
        // Chrome or Chrome on iOS
        return true;
      } else if ((/safari/i).test(userAgent)) {
        // Safari
        return true;
      }
      return false;
    }

  }

  reloadPage() {
    this.toastr.info("Reloading the page", "Internet Connectivity restored", {
      closeButton: true
    });
    setTimeout(() => {
      window.location.assign(
        window.location.href
      );
    }, 2000);
  }


  /**
   * @param workspaceSid
   * @param callback
   */
  private getTwilioWorkspaceActivities = (workspaceSid: string, callback: Function) => {
    this.getWorkspaceActivities = this.apiService.getWorkspaceActivities(workspaceSid).subscribe({
      next: (activitiesResult: getWorkspaceActivities) => {
        if (activitiesResult?.isActionSuccess) {
          this.twilioWorkspaceActivities = activitiesResult.activities;
          callback();
        }
      },
      error: (error: HttpErrorResponse) => { console.log("An error occurred", error); }
    });
  };



  ngOnDestroy(): void {
    this.getUserProfileDetail?.unsubscribe();
    this.getCodeCategory?.unsubscribe();
    this.getWorkspaceActivities?.unsubscribe();
    this.getValidPermission?.unsubscribe();
    this.postOauthUserChannel?.unsubscribe();
  }

  onInitBindings(): void {
    /**
     *
     */
    window.addEventListener("online", () => {
      if (this.dialogRef) {
        this.dialogRef.close();
      }

      if (this.reloadTimer) {
        clearTimeout(this.reloadTimer);
      }

      window.removeEventListener("online", this.reloadPage);
    });

    /**
     *
     */
    window.addEventListener("offline", () => {
      this.dialogRef = this.dialog.open(InternetConnectivityModalComponent, {
        panelClass: `internet-connectivity-modal`,
        disableClose: true,
        width: "900px",
        minHeight: "276px",
        scrollStrategy: this.overlay.scrollStrategies.noop(),
      });

      /* Reload the page  */
      this.reloadTimer = setTimeout(() => {
        window.addEventListener("online", this.reloadPage.bind(this));
      }, 30000);

    });


    /**
     *
     * @param e
     */
    window.onbeforeunload = (e: Event) => {
      window.localStorage.setItem("sessionId", this.getSessionId());
      window.sessionStorage.removeItem("sessionId");
      navigator.sendBeacon(`https://${window.location.hostname.split("-")[0]}-admin.${uiClientsConfig.reservedUrlDomain}/api/v1/services/twilio-microservice/reset-focus-channels`,
        JSON.stringify({
          channel: this.getUserStatus?.channel || "ERROR",
          oauthUserId: this.twilioWorker?.oauthUserId || this.deleteOauthUser,
          twilioWorkerId: this.twilioWorker?.id,
          twilioWorkerActivityId: this.window["offlineActivity"]?.id,
          twilioWorkerActivityName: this.window["offlineActivity"]?.friendlyName,
          isTwilioWorkerEnabled: this.isTwilioWorkerEnabled,
          workSpaceId: this.twilioWorker?.workSpaceId,
          navigationType: performance.navigation.type
        }));
    };

    /**
     *
     */
    this.localService.receiveTwilioCallNotification.subscribe(({ callStatus }) => {
      if (callStatus !== "BREAK" && callStatus !== "TRAINING" && callStatus !== "LUNCH" && callStatus !== "PERSONAL" && callStatus !== "RECEIVING_ASSISTANCE_OTHERS" && callStatus !== "GIVING_ASSISTANCE_OTHERS" && callStatus !== "TASKING_OTHERS")
        this.twilioCallNotificationMapLogic(callStatus);
      else {
        this.providerChannelAvailability(callStatus, "");
      }
    });

  }
  twilioCallNotificationMapLogic(callStatus: string) {
    let statusAction = this.oauthUserChannelActionMap[callStatus];
    if (statusAction) {
      if (statusAction.userStatus) {
        if (statusAction.isMatMenuValueBeingChanged) {
          if (this.window["currentMatMenuValueTracker"].getConstantValue() === "MAKING_CALLS" &&
            callStatus !== "RESERVATION_ACCEPTED" &&
            callStatus !== "RESERVATION_ENDED") {
            statusAction = this.oauthUserChannelActionMap["ACTIVITY_RESET_MAKING_CALLS"];
          }

          this.window["currentMatMenuValueTracker"].setValue(statusAction.userStatus);

        }
        this.userStatus = statusAction.userStatus;
      }
      if (statusAction.userStatusColor) {
        this.userStatusColor = statusAction.userStatusColor;
      }
      if (statusAction.isMatMenuAllowedToOpen !== undefined) {
        this.isMatMenuAllowedToOpen = statusAction.isMatMenuAllowedToOpen;
        if (this.matMenuTrigger && this.matMenuTrigger.menuOpen) {
          this.matMenuTrigger.closeMenu();
        }
      }

      if (statusAction.createOauthUserChannelAndUpdateWorkerStatus) {
        let [status, isTwilioCalls, isTwilioOutboundCalls] = statusAction.createOauthUserChannelAndUpdateWorkerStatus;
        if (this.window["currentMatMenuValueTracker"].getConstantValue() === "MAKING_CALLS") {
          isTwilioCalls = false; isTwilioOutboundCalls = true;
        }
        this.createOauthUserChannelAndUpdateWorkerStatus(status, isTwilioCalls, isTwilioOutboundCalls);
      }
    }
  }

  updateWorkerStatusToOffline() {
    if (this.window["twilioActivityUpdateFunction"] &&
      (typeof this.window["twilioActivityUpdateFunction"] === "function") && this.window["offlineActivity"]?.id) {
      this.window["twilioActivityUpdateFunction"](this.window["offlineActivity"].id);
    }
  };

  updateWorkerStatusToMakingCalls() {
    if (this.window["twilioActivityUpdateFunction"] &&
      (typeof this.window["twilioActivityUpdateFunction"] === "function" && this.window["makingCallsActivity"]?.id)) {
      this.twilioCallNotificationMapLogic("ACTIVITY_RESET_MAKING_CALLS");
      this.window["twilioActivityUpdateFunction"](this.window["makingCallsActivity"].id);
    }
  };

  initiateOauthUserLoginPooling(userName: string): void {
    /* update user login status after 2 mins */
    this.apiService.oauthUserLoginPooling(userName);
  }

  checkPermission(permissionName: string) {
    let propertyName = permissionName.split(" ").join("");
    let isSubAction = propertyName === "FocusDashboardGet"
    this.getValidPermission = this.apiService.getValidPermission(permissionName, isSubAction).subscribe({
      next: (result: any) => {
        if (result && result.data) {
          this.allPermission[propertyName] = result.data.hasPermission;
          if (isSubAction) {
            for (let i = 0; i < result.data.length; i++) {
              this.allPermission[propertyName] = true;
              const words = result.data[i].subAction.toLowerCase().split("_");
              const capitalizedWords = words.map((word: string) => word.charAt(0).toUpperCase() + word.slice(1));
              const keyName = capitalizedWords.join("");
              this.allSubPermission[keyName] = true;
              if (this.allSubPermission["FocusDashboardRealtime"]) {
                this.defaultDashboardRoute = "real-time";
              } else if (this.allSubPermission["FocusDashboardDigitalEngagement"]) {
                this.defaultDashboardRoute = "digital-engagement";
              } else if (this.allSubPermission["FocusDashboardLiveVoice"]) {
                this.defaultDashboardRoute = "live-voice";
              }
            }
          }
          if (propertyName === "OutboundDailpadGet") {
            this.localService.sendDialPadPermission(result.data.hasPermission)
          }
        } else {
          if (propertyName === "OutboundDailpadGet") {
            this.localService.sendDialPadPermission(false)
          }
        }

      },
      error: (error: HttpErrorResponse) => { console.log("An error occurred", error); }
    })
  }

  ngOnInit(): void {
    this.userStatus = JSON.parse(window.localStorage.getItem("previousStatus") || '"Getting Ready"');
    this.onInitBindings();
    this.isNavbarComponentLoaded = true;
    let uiPermissions = [
      "Outbound Dailpad Get", 
      "Get Patient Details",
      "Inbox Focus Get", 
      "Focus Reports Get", 
      "Focus Dashboard Get",
      "Post Patient File Upload",

    ];

    for (let i = 0; i < uiPermissions.length; i++) {
      this.checkPermission(uiPermissions[i]);
    }
    window.localStorage.setItem('previousStatus', JSON.stringify(this.userStatus));
    this.getUserProfileDetail = this.apiService.getUserProfileDetail().subscribe({
      next: (result: getUserProfileDetail) => {
        this.localService.setUserProviderId(result.data.id);
        this.oauthUserName = result.data.orgEntityName || result.data.userName;
        this.oauthEmailAddress = result.data.emailAddress;
        this.window["oauthUserId"] = this.deleteOauthUser = result.data.id || "";
        this.twilioWorker = result.data.twilioWorker || {} as twilioWorker;
        this.orgEntityId = result.data.orgEntityId || "";
        this.isTwilioWorkerEnabled = result.data.isTwilioWorkerEnabled || false;
        this.oauthUserChannel = result.data.oauthUserChannel;
        setInterval(() => {
          this.initiateOauthUserLoginPooling(this.oauthUserName)
        }, 120000);
        this.initiateOauthUserLoginPooling(this.oauthUserName);
        /**Setting initial tracker for mat menu status */
        this.window["currentMatMenuValueTracker"] = this.matMenuValueTrackerClass("");
        if (this.isTwilioWorkerEnabled) {
          this.getTwilioWorkspaceActivities(this.twilioWorker.workSpaceId, () => {
            this.setSessionUserChannelAvailability();
          });
        }
        else {
          this.setSessionUserChannelAvailability();
        }
        this.oauthUserProfilePictureUrl = result.data.profilePictureUrl.includes("fetch-dynamic-resource") ? `${this.imgUrlPrefix}/${result.data.profilePictureUrl}` : `${this.imgUrlPrefix}/${result.data.profilePictureUrl}`;
      },
      error: (error: HttpErrorResponse) => { console.log("An error occurred", error); }
    });

    this.getCodeCategory = this.apiService.getCodeCategory().subscribe({
      next: (result: getCodeCategory) => {
        this.getUserStatus.channelId = result.data[4]?.id;
      },
      error: (error: HttpErrorResponse) => { console.log("An Error Occurred", error); }
    });

  }
}
