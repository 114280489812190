import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { CookieService } from 'ngx-cookie-service';
import { BehaviorSubject, catchError, Observable, of, switchMap } from 'rxjs';
import { uiClientsConfig, reservedCodeCategories } from 'config';
import { filter, take } from 'rxjs/operators';

const AUTH_API: string = '/api/v1/idp/oauth2-token';

@Injectable({
    providedIn: 'root'
})
export class AuthService {
    constructor(private cookieService: CookieService, private http: HttpClient) { }
    //token = this.cookieService.get('access_token')
    subdomain: string = window.location.hostname.split("-")[0];
    customerUrlPrefix: string = this.subdomain + '-admin';
    cookie: string = this.cookieService.get(this.subdomain + '-admin');
    tokenCookie: any;
    token: string;
    httpOptions: any;

    private refreshTokenInProgress = false;
    private refreshTokenSubject: BehaviorSubject<any> = new BehaviorSubject<any>(null);

    checkTokenEx(): Observable<string> {
        if (this.isTokenExpired()) {
            // If token is expired, initiate a refresh token request
            if (!this.refreshTokenInProgress) {
                this.refreshTokenInProgress = true;
                this.refreshTokenSubject.next(null);
                var cookie = this.cookieService.get(this.customerUrlPrefix);
                var token = JSON.parse(cookie);
                const body = new URLSearchParams();
                body.set('grant_type', "refresh_token");
                body.set('refresh_token', token.refresh_token);
                body.set('client_id', reservedCodeCategories.reservedPasswordGrantTypeClientId)
                var accessToken = token.access_token
                this.httpOptions = {
                    headers: new HttpHeaders({
                        'Content-Type': 'application/x-www-form-urlencoded',
                        'Authorization': `Bearer ${accessToken}`
                    })
                };
                return this.http.post(AUTH_API, body.toString(), this.httpOptions).pipe(
                    switchMap((response: any) => {
                        // If refresh token request is successful, update the token in cookie
                        let stringifyToken = JSON.stringify(response);
                        this.cookieService.deleteAll();
                        window.document.cookie = this.customerUrlPrefix + "=" + encodeURIComponent(stringifyToken) + ';path=/;domain=.' + uiClientsConfig.reservedUrlDomain;
                        this.refreshTokenInProgress = false;
                        // Notify all pending requests to retry with the updated access token
                        this.refreshTokenSubject.next(response.access_token);
                        return of(response);
                    }),
                    catchError((error) => {
                        // If refresh token request fails, log the error and return an empty string
                        console.error(error);
                        this.refreshTokenInProgress = false;
                        return of(null);
                        //return throwError(error);
                    })
                ) as Observable<string>;
            } else {
                // If a refresh token request is already in progress, wait until it completes
                return this.refreshTokenSubject.pipe(
                    filter((token) => token !== null),
                    take(1),
                    switchMap((token) => {
                        // Once the refresh token request completes, retry the original request with the updated token
                        return this.checkTokenEx();
                    })
                );
            }
        } else {
            var cookie = this.cookieService.get(this.customerUrlPrefix);
            var token = JSON.parse(cookie);
            // If token is not expired, return the original token as an observable
            return new Observable<string>((observer) => {
                observer.next(token);
                observer.complete();
            });
        }
    }

    private isTokenExpired(): boolean {
        // Check if the token is expired
        var cookie = this.cookieService.get(this.customerUrlPrefix);
        var token = JSON.parse(cookie);
        var now = new Date();
        let accessToken = (token && token.access_token_expires_at) ? token.access_token_expires_at : '';
        let refreshToken = (token && token.refresh_token_expires_at) ? token.refresh_token_expires_at : '';
        var accessTokenDate = new Date(accessToken);
        var refreshTokenDate = new Date(refreshToken);
        if (now.getTime() >= accessTokenDate.getTime() && now.getTime() <= refreshTokenDate.getTime()) {
            return true;
        } else {
            return false;
        }
    }

    refreshToken(grant_type: string, refresh_token: string, client_id: string) {
        const body = new URLSearchParams();
        body.set('grant_type', grant_type);
        body.set('refresh_token', refresh_token);
        body.set('client_id', client_id)
        if (this.cookie) {
            this.tokenCookie = JSON.parse(this.cookie);
            this.token = this.tokenCookie.access_token
            this.httpOptions = {
                headers: new HttpHeaders({
                    'Content-Type': 'application/x-www-form-urlencoded',
                    'Authorization': `Bearer ${this.token}`
                })
            };
        }
        return this.http.post(AUTH_API, body.toString(), this.httpOptions);
    }

    logoutOauthUser(token) {
        var url = "/api/v1/platform/logout";
        var request = new XMLHttpRequest();
        request.open("PUT", url, false);

        request.setRequestHeader("Content-type", "application/json;charset=UTF-8");
        request.setRequestHeader("Authorization", "Bearer " + token.access_token);

        request.send();
        if (request.readyState === 4) {
            if (request.status === 200) {
                var response = JSON.parse(request.responseText);
                return (response);
            } else {
                return ("error");
            }
        } else {
            return ("error");
        }
    }
}