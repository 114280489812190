import { DragDropModule } from '@angular/cdk/drag-drop';
import { CommonModule, DOCUMENT } from '@angular/common';
import { Component, ElementRef, HostListener, Inject, OnInit, ViewChild, Input } from '@angular/core';
import { MatExpansionModule } from '@angular/material/expansion';
import { LocalServiceService, participantObject } from 'src/app/services/local-service.service';
import { TwilioWorkerService } from 'src/app/services/worker.service';
import { TimerComponent } from './call-timer.component';
import { Router, RouterModule } from '@angular/router';
import { InboxService } from '../../inbox/inbox.service';
import { RandomBackgroundColorGeneratorDirective } from 'src/app/directive/random-backgroundcolor-generator.directive';
import { MatDialog, MatDialogModule } from '@angular/material/dialog';
import { Overlay } from '@angular/cdk/overlay';
import { ConferenceModalComponent } from '../../inbox/calls/conference-modal/conference-modal.component';
import { TransferConsultModalComponent } from '../../inbox/transfer-consult-modal/transfer-consult-modal.component';
import { FormsModule } from '@angular/forms';
import { ToastrModule, ToastrService } from 'ngx-toastr';
import { SearchOrAssociatePatientModalComponent } from '../../inbox/calls/search-or-associate-patient-modal/search-or-associate-patient-modal.component';
import { SearchOrAssociatePatientModalService } from '../../inbox/calls/search-or-associate-patient-modal/search-or-associate-patient-modal.service';
import { CreateNewPatientModalComponent } from '../../patients/create-new-patient-modal/create-new-patient-modal.component';
import { CamelCasePipePipe } from '../../pipes/camel-case-pipe.pipe';
import { customWindow, permission } from 'src/custom';
import { callLoggingObject, callObject, ConferenceCallback, outboundConferenceCallInterface, reservation, sendTwilioCallActivityStatus, validateOutboundCall } from './interface';
import { searchMember, SearchOrAssociatePatientModalComponentInterface } from '../../inbox/calls/search-or-associate-patient-modal/search-or-associate-patient-modal-interface';
import { TwilioError } from '@twilio/voice-sdk/es5/twilio/errors';
import { HttpErrorResponse } from '@angular/common/http';
import { callerDetail } from '../../inbox/calls/interface';
import { Subscription } from 'rxjs';
import { workerOfWorkerInstance } from 'src/app/twilio-connection/twilio-connection';
import { uiClientsConfig } from 'config';


@Component({
  standalone: true,
  imports: [CommonModule, FormsModule, MatDialogModule, MatExpansionModule, DragDropModule, TimerComponent, RouterModule, RandomBackgroundColorGeneratorDirective, TransferConsultModalComponent, CamelCasePipePipe, ToastrModule],
  selector: 'app-call-slideup-popover',
  templateUrl: './call-slideup-popover.component.html',
  styleUrls: ['./call-slideup-popover.component.css']
})
export class CallSlideupPopoverComponent implements OnInit {
  @ViewChild("dialerInput") dialerInput: ElementRef = {} as ElementRef;
  @Input() allPermission: permission = {} as permission;

  constructor(
    private dialog: MatDialog,
    private localService: LocalServiceService,
    public searchOrAssociatePatientModalService: SearchOrAssociatePatientModalService,
    private twilioWorkerService: TwilioWorkerService,
    private router: Router,
    public inboxService: InboxService,
    private toastr: ToastrService,
    private overlay: Overlay,
    @Inject(DOCUMENT) private document: Document) {
    this.window["showModal"] = this.showModal.bind(this);
    this.window["hideModal"] = this.hideModal.bind(this);
    this.window["dummyFunction"] = this.dummyFunction.bind(this);
  }

  window: customWindow = window;
  validateOutboundCall: Subscription = new Subscription();
  outboundConferenceCall: Subscription = new Subscription();
  outboundCallTask: Subscription = new Subscription();
  subDomain: string = window.location.host.split("-focus")[0];
  incomingCallAudio: HTMLAudioElement = new Audio('https://medevolve-voice-content.s3.us-east-2.amazonaws.com/incoming-twilio-call.mp3');
  defaultUserImgUrl: string = "img/default-user.png";
  patientPhoneNumber: string = "";
  orgEntityId: string | undefined = "";
  providerId: string = "";
  dialPadPermission: boolean = false;
  userStatus: string = "";
  dummyReservation: any = {
    "task": {
      "workspaceSid": "WSc3a0654d628b9b36b6effff13486b330",
      "workspace": {
        "activities": {},
        "workflows": {},
        "taskqueues": {
          "statistics": {},
          "realtimeStats": {},
          "cumulativeStats": {}
        },
        "workers": {
          "statistics": {},
          "realtimeStats": {},
          "cumulativeStats": {}
        },
        "tasks": {},
        "statistics": {},
        "realtimeStats": {},
        "cumulativeStats": {}
      },
      "assignmentStatus": "reserved",
      "dateUpdated": "2023-04-18T17:58:04.000Z",
      "taskQueueEnteredDate": "2023-04-18T17:57:59Z",
      "age": 5,
      "sid": "WT5267adae16f6a3963a61845b73c7a9b8",
      "priority": 1,
      "reason": null,
      "taskQueueSid": "WQ708220945f4e706db66541e1c4717a0a",
      "workflowFriendlyName": "Med Evolve PULL-PUSH",
      "timeout": 600,
      "attributes": {
        "from_country": "US",
        "called": "+17279662246",
        "to_country": "US",
        "to_city": "",
        "selected_product": "support",
        "to_state": "FL",
        "caller_name": "",
        "caller_country": "US",
        "call_sid": "CA262ab17cdfbe8fe39ab58993026802c5",
        "from_zip": "77433",
        "from": "+16302568396",
        "direction": "inbound",
        "called_zip": "",
        "caller_state": "TX",
        "to_zip": "",
        "called_country": "US",
        "from_city": "HOUSTON",
        "called_city": "",
        "caller_zip": "77433",
        "api_version": "2010-04-01",
        "called_state": "FL",
        "from_state": "TX",
        "caller": "+12816569419",
        "caller_city": "HOUSTON",
        "to": "+17279662246",
        "selectedWorker": [
          "WK41a179ddd90911d4a7e3d87a678c0f71"
        ]
      },
      "dateCreated": "2023-04-18T17:57:59.000Z",
      "taskChannelSid": "TC39628179e205aa899c00f6ce11133e30",
      "addons": {},
      "taskChannelUniqueName": "voice",
      "workflowSid": "WW4d26ab930dcb9136cb0065e585fda90c",
      "workflow": {
        "statistics": {},
        "realtimeStats": {},
        "cumulativeStats": {}
      },
      "taskQueueFriendlyName": "Testing QUEUE PULL-PUSH"
    },
    "sid": "WR67f7a4accdcc3c6378351560b0dec2ab",
    "workerSid": "WK41a179ddd90911d4a7e3d87a678c0f71",
    "worker": {
      "statistics": {}
    },
    "taskSid": "WT5267adae16f6a3963a61845b73c7a9b8",
    "dateUpdated": "2023-04-18T17:58:04.000Z",
    "workspaceSid": "WSc3a0654d628b9b36b6effff13486b330",
    "workspace": {
      "activities": {},
      "workflows": {},
      "taskqueues": {
        "statistics": {},
        "realtimeStats": {},
        "cumulativeStats": {}
      },
      "workers": {
        "statistics": {},
        "realtimeStats": {},
        "cumulativeStats": {}
      },
      "tasks": {},
      "statistics": {},
      "realtimeStats": {},
      "cumulativeStats": {}
    },
    "reservationStatus": "pending",
    "workerName": "shiv",
    "dateCreated": "2023-04-18T17:58:04.000Z"
  };

  toTwilioNumber: number = NaN;
  taskSid: string = "";
  isCall: boolean = false;
  isCallPicked: boolean = false;
  openCallPanel: boolean = false;
  index: number = 0;
  patientName: string = "";
  profilePicture: string = "";
  patientInitials: string = "";
  totalAgentCount: number = 0;
  workerThis: reservation = {} as reservation;
  minimize: boolean = false;
  isCallConnected = false;
  isCallConnecting: boolean = false;
  incomingCall: reservation = {} as reservation;
  isCallIncoming: boolean = false;
  muteStatus: boolean = false;
  holdStatus: boolean = false;
  isTwilioCallsEnabled: boolean = false;
  isHangupClicked: boolean = false;
  isNumberValidating: boolean = false;
  isCallDisconnectOptionsVisible: boolean = false;
  workerId: string | undefined = "";
  isModalOpen: boolean = false;
  viewportBoundary: HTMLElement = {} as HTMLElement;
  associatedPatientName: string | undefined = "";
  formattedPhoneNumber: string | undefined = "";
  isDialerVisible: boolean = true;
  callSidOutgoing: string = "";
  isOutboundCall: boolean = false;
  openMainPanel: boolean = false;
  uiFormattedNumber: string = "";
  uiFormattedNumberCopy: string = "";
  dialedNumber: string = "";
  isDisabled: boolean = false;
  sendTwilioCallActivityStatus: Subscription = new Subscription();

  hangUpOrShowOptions(num: number) {
    num > 1 ? this.isCallDisconnectOptionsVisible = true : this.hangUpCall();
  }

  /* If only one agent is present in the conference, hang up the call */
  hangUpCall() {
    this.isCall = true;
    this.isCallPicked = false;
    this.profilePicture = "";
    this.hideModal();
    this.isCallDisconnectOptionsVisible = false;
    window.localStorage.setItem("completeBtnClicked", String(false));
    let rawData: reservation["task"] = Object.assign({}, this.workerThis.task);
    var object: callLoggingObject = {
      orgEntityId: this.orgEntityId,
      callSid: this.workerThis?.task?.attributes?.call_sid ? this.workerThis.task.attributes.call_sid : "Outbound Dial",
      callStatus: "PROVIDER_SIDE_CALL_ENDED",
      callDescription: "provider Side Call Ended",
      callRawData: JSON.stringify(rawData),
      providerId: this.providerId,
      callFrom: this.workerThis?.task?.attributes?.from,
      callTo: this.workerThis?.task?.attributes?.to
    }
    this.twilioWorkerService.hangOngoingCall({
      conferenceId: this.incomingCall?.task?.attributes?.conference?.sid,
      callerId: this.incomingCall?.task?.attributes?.conference?.participants?.customer,
      taskId: this.incomingCall?.task?.sid
    }).then(() => {
      this.sendTwilioCallActivityStatus = this.inboxService.sendTwilioCallActivityStatus(object).subscribe();
      this.inboxService.setCallCompletedWorkerTask(JSON.stringify(rawData));
    }).catch(err => {
      console.log(err)
    });
  }

  /* If multiple agents are present, then remove the current agent */
  leaveCall() {
    this.isCall = true;
    this.isCallPicked = false;
    this.profilePicture = "";
    this.hideModal();
    this.isCallDisconnectOptionsVisible = false;
    window.localStorage.setItem("completeBtnClicked", String(false));
    let rawData: reservation["task"] = Object.assign({}, this.workerThis.task);
    var object: callLoggingObject = {
      orgEntityId: this.orgEntityId,
      callSid: this.workerThis?.task?.attributes?.call_sid ? this.workerThis.task.attributes.call_sid : "Outbound Dial",
      callStatus: "PROVIDER_SIDE_CALL_ENDED",
      callDescription: "provider Side Call Ended",
      callRawData: JSON.stringify(rawData),
      providerId: this.providerId,
      callFrom: this.workerThis?.task?.attributes?.from,
      callTo: this.workerThis?.task?.attributes?.to
    }
    this.twilioWorkerService.leaveOngoingCall({
      conferenceSid: this.incomingCall?.task?.attributes?.conference?.sid,
      calledId: this.incomingCall?.task?.attributes?.conference?.participants?.worker,
      taskSid: this.incomingCall?.task?.sid
    }).then(() => {
      this.sendTwilioCallActivityStatus = this.inboxService.sendTwilioCallActivityStatus(object).subscribe();
      this.inboxService.setCallCompletedWorkerTask(JSON.stringify(rawData));
    }).catch(err => {
      console.log(err)
    });
  }

  /** put call on mute/unmute */
  callMuteStatus() {
    this.muteStatus = !this.muteStatus;
    const callObject: callObject = {
      conferenceId: this.incomingCall?.task?.attributes?.conference?.sid,
      callerId: this.incomingCall?.task?.attributes?.conference?.participants?.worker,
      muted: this.muteStatus
    };
    let rawData: reservation["task"] = Object.assign({}, this.workerThis.task);
    var object: callLoggingObject = {
      orgEntityId: this.orgEntityId,
      callSid: this.workerThis?.task?.attributes?.call_sid ? this.workerThis.task.attributes.call_sid : "Outbound Dial",
      callStatus: this.muteStatus ? "CALL_MUTED" : "CALL_UNMUTED",
      callDescription: this.muteStatus ? "Call Muted" : "Call UnMuted",
      callRawData: JSON.stringify(rawData),
      providerId: this.providerId,
      callFrom: this.workerThis?.task?.attributes?.from,
      callTo: this.workerThis?.task?.attributes?.to
    }

    this.twilioWorkerService.callMuteStatus(callObject).then(() => {
      this.sendTwilioCallActivityStatus = this.inboxService.sendTwilioCallActivityStatus(object).subscribe();
    })
  }

  /** put call on hold/unhold */
  callHoldStatus() {
    this.holdStatus = !this.holdStatus;
    this.localService.changeHoldStatus(this.holdStatus);
  }

  extractFromToAndCallerName(reservationObject: reservation) {
    const { attributes }: reservation["task"] = reservationObject.task;
    const from: string | undefined = attributes?.from;
    const to: string | undefined = attributes?.to;
    const callerName: string | undefined = attributes?.caller_name;
    const taskId: string | undefined = reservationObject?.taskSid;
    const workerId: string | undefined = reservationObject?.workerSid;
    this.workerId = workerId;
    const workSpaceId: string | undefined = reservationObject?.workspaceSid;
    return { ...reservationObject, from, to, callerName, isPullBased: false, workSpaceId, workerId, taskId };
  }

  dummyFunction(callStatus: string) {
    if (callStatus === "RESERVATION_ACCEPTED") {
      this.isCall = false;
      this.isCallPicked = true;
      this.isCallConnected = true;
      this.isCallConnecting = false;
      // this.incomingCall = reservation;
      // this.localService.enableCallBtns(false);
    } else if (callStatus === "RESERVATION_CREATED") {
      // this.isCallConnecting = false;
      this.isCall = true;
      this.isCallPicked = false;
      this.isCallConnected = true;
      setTimeout(function () {
        $("#a").removeClass().addClass("fa fa-microphone-slash");
      });
      // this.incomingCall = reservation;
      // this.workerThis = Object.assign(this, __this)
    }
    else if (callStatus === "RESERVATION_ENDED") {
      this.isCallConnected = false;
      this.incomingCall = {} as reservation;
      this.localService.enableCallBtns(true);
    }
  }

  showModal() {
    this.isCallConnected = true;
    this.isCall = true;
    this.incomingCall = this.extractFromToAndCallerName(this.dummyReservation);
  };

  hideModal() {
    this.isCallConnected = false;
  };

  connectIncomingCall(reservation: reservation) {
    if (reservation.task?.attributes?.isOutboundConferenceTask) {
      this.localService.setInboxTabIndex(0);
    }
    if (!reservation.task?.attributes?.isOutboundConferenceTask) {
      window.localStorage.setItem("isPatientAssociated", JSON.stringify(false));
    }
    this.isCall = false;
    window.localStorage.setItem("completeBtnClicked", String(false));
    this.isCallConnecting = true;
    this.inboxService.setTwilioNumber(reservation.task.attributes.to);
    // this.isCallPicked = true;
    this.inboxService.changeIncomingNumber(reservation.task?.attributes?.from);
    this.inboxService.setTaskSid(reservation.taskSid);
    this.pickCallRequest(reservation);
  }

  async setCallAuditStatusAndParameters() {
    let rawData = Object.assign({}, this.workerThis.task);
    var object = {
      orgEntityId: this.orgEntityId,
      callSid: this.callSidOutgoing,
      callStatus: "CALL_ACCEPTED",
      callDescription: "Call Accepted",
      callRawData: JSON.stringify(rawData),
      providerId: this.providerId,
      callFrom: this.workerThis.task?.attributes?.from,
      callTo: this.workerThis.task?.attributes?.to,
      isOutboundCall: true
    }
    this.localService.setCallAuditParameters(object.callSid, object.callFrom, object.callTo, rawData, object.providerId, object.isOutboundCall);
    this.sendTwilioCallActivityStatus = this.inboxService.sendTwilioCallActivityStatus(object).subscribe();

    let knownPatientCallAudit = {
      patientType: this.orgEntityId ? "KNOWN_PATIENT" : "UNKNOWN",
      callSid: this.callSidOutgoing,
      fromNumber: this.workerThis.task?.attributes?.from,
      toNumber: this.workerThis.task?.attributes?.to,
      rawCallData: JSON.stringify(rawData),
      providerId: this.providerId,
      orgEntityId: this.orgEntityId,
      isOutboundCall: true
    }
    this.inboxService.sendTwilioCallAuditStatus(knownPatientCallAudit);
  }

  pickCallRequest(reservation: reservation) {
    // this.inboxService.setIncomingCallStatus(true);
    if (reservation.task?.attributes?.isOutboundConferenceTask) {

      let obj = {
        taskSid: reservation.task.sid,
        workerSid: reservation.workerSid,
        contactUri: `client:${reservation.workerName}`,
        customerNumber: reservation.task.attributes.caller,
        twilioNumber: reservation.task.attributes.called
      };
      this.outboundConferenceCall = this.inboxService.outboundConferenceCall(obj).subscribe({
        next: async (result: outboundConferenceCallInterface) => {
          this.callSidOutgoing = result.customerCallSid;
          await reservation.accept();
          await this.setCallAuditStatusAndParameters();
        },
        error: (error: HttpErrorResponse) => { console.log("An Error Occurred", error); }
      })
    } else if (!reservation.task?.attributes?.isTransferTask && !reservation.task?.attributes?.isConferenceTask) {
      const options = {
        "ConferenceStatusCallback": `https://${this.subDomain}-twilio.${uiClientsConfig.reservedUrlDomain}/api/twilio-public-endpoints/conference-events`,
        "ConferenceStatusCallbackEvent": "start,end,join,leave,mute",
        "EndConferenceOnExit": "false",
        "EndConferenceOnCustomerExit": "true",
        "ConferenceRecord": "true",
        "ConferenceRecordingStatusCallback": `https://${this.subDomain}-twilio.${uiClientsConfig.reservedUrlDomain}/api/twilio-public-endpoints/conference-recording`
      };
      reservation.conference(null, null, null, null, function (error: TwilioError | null, reservation: reservation | null) {
        if (error) {
          console.log("Error: ", error);
        } else {
          // console.log("Reservation: ", reservation);
        }
        return;
      }, options);
      let rawData: reservation["task"] = Object.assign({}, this.workerThis.task);
      var object: callLoggingObject = {
        orgEntityId: this.orgEntityId,
        callSid: this.workerThis.task?.attributes?.call_sid,
        callStatus: "CALL_ACCEPTED",
        callDescription: "Call Accepted",
        callRawData: JSON.stringify(rawData),
        providerId: this.providerId,
        callFrom: this.workerThis?.task?.attributes?.from,
        callTo: this.workerThis?.task?.attributes?.to,
        isOutboundCall: false
      }
      this.localService.setCallAuditParameters(object.callSid, object.callFrom, object.callTo, rawData, object.providerId, object.isOutboundCall);
      this.sendTwilioCallActivityStatus = this.inboxService.sendTwilioCallActivityStatus(object).subscribe();
    } else {
      reservation.call(
        null,
        `https://${this.subDomain}-twilio.${uiClientsConfig.reservedUrlDomain}/api/twilio-public-endpoints/conference-call-connect/${reservation.task.attributes.conference.room_name}/${reservation.task.attributes.conference.participants.worker}`,
        null,
        "true",
        null
      );
      let rawData: reservation["task"] = Object.assign({}, this.workerThis.task);
      var object: callLoggingObject = {
        orgEntityId: this.orgEntityId,
        callSid: this.workerThis.task?.attributes?.call_sid,
        callStatus: "CALL_ACCEPTED",
        callDescription: "Call Accepted",
        callRawData: JSON.stringify(rawData),
        providerId: this.providerId,
        callFrom: this.workerThis?.task?.attributes?.from,
        callTo: this.workerThis?.task?.attributes?.to,
        isOutboundCall: false
      }
      this.localService.setCallAuditParameters(object.callSid, object.callFrom, object.callTo, rawData, object.providerId, object.isOutboundCall);
      this.sendTwilioCallActivityStatus = this.inboxService.sendTwilioCallActivityStatus(object).subscribe();
    }
  }

  // open conference modal
  openConferenceModal() {
    var isOpenConferenceModal = this.inboxService.getOpenConference();
    if (!isOpenConferenceModal && !this.isModalOpen) {
      if (this.window["currentMatMenuValueTracker"].getCurrentValue() !== "MAKING_CALLS") {
        this.isModalOpen = true;
        const dialogRef = this.dialog.open(ConferenceModalComponent, {
          panelClass: `conference-modal`,
          data: {
            orgEntityId: this.orgEntityId,
          },
          disableClose: false,
          width: '757px',
          minHeight: '392px',
          scrollStrategy: this.overlay.scrollStrategies.noop(),
        });
        dialogRef.afterClosed().subscribe((result) => {
          this.isModalOpen = false;
          this.inboxService.setOpenConference(false);
          if (result && result.isTransferButtonDisabled && result.timeLeft && Date.now() - result.timeLeft < 90000) {
            var timeout;
            let remainTime = Date.now() - result.timeLeft;
            this.inboxService.isTransferEnabled(true)
            clearTimeout(timeout);
            timeout = setTimeout(() => {
              if (this.totalAgentCount && this.totalAgentCount <= 1) {
                this.inboxService.isTransferEnabled(false);
              }
            }, 90000 - remainTime);
          }
        });
      }
      this.inboxService.setOpenConference(true);
    }
  }

  redirectTo() {
    this.router.navigateByUrl('/', { skipLocationChange: true }).then(() =>
      this.router.navigate(['focus/inbox', { type: 2 }]));
  }

  setViewportBoundary() {
    if (this.isCallConnected || this.isCall) {
      this.removeViewportBoundary();
      const viewportWidth = Math.max(document.documentElement.clientWidth || 0, window.innerWidth || 0);
      const viewportHeight = Math.max(document.documentElement.clientHeight || 0, window.innerHeight || 0);
      this.viewportBoundary = this.document.createElement('div');
      this.viewportBoundary.setAttribute("id", "call-drag-boundary");
      Object.assign(this.viewportBoundary.style, {
        top: '0px',
        right: `${viewportWidth}px`,
        bottom: `${viewportHeight}px`,
        left: '0px',
        position: 'fixed',
        height: '100%',
        width: "100%",
        'z-index': '-10'
      });
      this.document.body.appendChild(this.viewportBoundary);
    }
  }
  removeViewportBoundary() {
    this.document.getElementById("call-drag-boundary")?.remove();
  }


  @HostListener('window:resize')
  onResize() {
    this.setViewportBoundary();
  }

  //Call Button and Dialer Start
  openDialer() {
    this.openMainPanel = !this.openMainPanel;
    if (this.openMainPanel) {
      setTimeout(() => {
        this.dialerInput.nativeElement.focus();
      }, 100)
    };
  }

  formatNumber() {
    this.formatPhoneNumberAndValidate();
  }

  clickingOnDialer(num: string) {
    this.uiFormattedNumber = this.uiFormattedNumber + num;
    this.dialerInput.nativeElement.focus();
    this.formatNumber();
  }

  formatPhoneNumberAndValidate() {
    this.isDisabled = false;
    var filterPhoneNumber = function (phoneNumber: string) {
      if (!phoneNumber) { return ''; }
      var value = phoneNumber.toString().trim().replace(/^\+/, '');
      if (value.match(/[^0-9*.+#]+$/)) {
        return phoneNumber;
      }
      var number = value;
      if (number) {
        if (number.length > 6) {
          number = number.slice(0, 3) + '-' + number.slice(3, 6) + '-' + number.slice(6, 10);
        }
        else if (number.length > 3) {
          number = number.slice(0, 3) + '-' + number.slice(3, 6);
        } return ('+1 ' + number).trim();
      }
      else { return '+1 ' + number; }
    };

    this.uiFormattedNumber = this.uiFormattedNumber?.replace(/-/g, "");
    this.uiFormattedNumber = this.uiFormattedNumber?.replace(/\(|\)/g, '');
    if (this.uiFormattedNumber?.includes('+')) { this.uiFormattedNumber = this.uiFormattedNumber?.slice(3); }
    this.uiFormattedNumber = filterPhoneNumber(this.uiFormattedNumber);
    this.dialedNumber = this.uiFormattedNumber?.replace(/[^\d+]/g, "");

  }

  checkNumber(event: KeyboardEvent) {
    /**check the value of the keyPress to make sure it's a num. which from 48-57 are 0-9 */
    if ((event.which !== 8 && event.which < 48) || event.which > 57) {
      event.preventDefault();
    }
  }

  onPaste(event: ClipboardEvent) {
    const pastedText: string | undefined = event.clipboardData?.getData('text/plain');
    if (pastedText) {
      const isNumeric = /^(\+\d{1,2}\s?)?(\(\d{3}\)|\d{3})[-.]?\d{3}[-.]?\d{4}$/.test(pastedText);
      if (!isNumeric) {
        event.preventDefault();
      }
    }
  }

  validateNumber() {
    if (this.dialedNumber) {
      if (!/^\+\d{11}$/.test(this.dialedNumber)) {
        this.toastr.warning("Phone Number is not correct. Please enter a valid Phone Number.");
        return false;
      }
      // this.router.navigate(['/focus/inbox']);
      return true;
    }
    else if (!this.dialedNumber) {
      this.toastr.warning("Please enter the number");
      return false;
    }
    // this.router.navigate(['/focus/inbox']);
    return true;
  }

  backspace() {
    this.uiFormattedNumber = this.uiFormattedNumber?.slice(0, -1);
    this.dialerInput.nativeElement.focus();
    this.formatNumber();
  }

  initializeOutboundCallTask(id: string | undefined) {
    let phoneNumber = this.dialedNumber.slice(2);
    this.validateOutboundCall = this.inboxService.validateOutboundCall(phoneNumber, id).subscribe({
      next: (result: validateOutboundCall) => {
        this.isNumberValidating = false;
        this.toastr.clear();
        this.formatNumber();
        if (result && result.isActionSuccess) {
          let callFrom: string = result.fromPhoneNumber;
          this.inboxService.setTwilioNumber(callFrom);
          let workflowId: string | undefined = result.workflowId;
          let workerId = this.window["windowTwilioWorkerInstance"].workerId;
          this.outboundCallTask = this.inboxService.outboundCallTask(workerId, this.dialedNumber, callFrom, workflowId).subscribe({
            next: (result: null) => {
              this.uiFormattedNumberCopy = this.uiFormattedNumber;
              this.uiFormattedNumber = "";
              this.dialedNumber = "";
            },
            error: (error: HttpErrorResponse) => { console.log("An error Occurred in initializing outbound call", error); }
          });
        }
        else {
          this.uiFormattedNumber = "";
          this.dialedNumber = "";
          this.toastr.warning("Phone Number is not correct. Please enter a valid Phone Number.");
          this.isDialerVisible = true;
        }
      },
      error: (error: HttpErrorResponse) => { console.log("An Error Occurred in validating call", error) }
    })

  }

  initializeCall() {
    if (this.validateNumber()) {
      this.toastr.info("Please wait while the number is being validated.");
      this.isNumberValidating = true;
      this.localService.callLeavedOrHangedup("Outbound_Call_INIT");
      this.isDisabled = false;
      this.window["displayCreatePatient"] = false;
      // let phoneNumber = this.dialedNumber.slice(2);
      if (!this.window["isMicroServiceSocketConnected"] ||
        !this.window["microServiceTwilioSocketConnection"] ||
        !this.window["microServiceTwilioSocketConnection"]?.connected) {
        this.localService.initiateTwilioSocketConnection(this.window["oauthUserId"]);
      }
      let dialedNumber = this.dialedNumber?.trim()?.slice(-10)
      this.localService.setAssociatedPatientOrgId("");
      this.searchOrAssociatePatientModalService.searchUnknownPatient({ pageNumber: 1, firstName: "", lastName: "", dateOfBirth: "", emailAddress: "", cellPhone: dialedNumber })
        .subscribe({
          next: (result: searchMember) => {
            this.isDialerVisible = false;
            this.openMainPanel = !this.openMainPanel;
            if (result.count <= 1) {
              if (result.count == 1) {
                // this.localService.setAssociatedPatientOrgId(result.data[0].patientId);
                this.orgEntityId = result.data[0].patientId;
              } else {
                this.localService.setAssociatedPatientOrgId("UNKNOWN_PATIENT");
                this.orgEntityId = "";
              }
              this.initializeOutboundCallTask(this.orgEntityId);
            } else {
              this.searchOrAssociateModal();
            }
          },
          error: (error: HttpErrorResponse) => { console.log("An error occurred in getting associated members", error); }
        })
    }
  }

  searchOrAssociateModal() {
    const dialogRef = this.dialog.open(SearchOrAssociatePatientModalComponent, {
      data: {
        allPermission: this.allPermission,
        isOpenedForOutboundCalling: true,
        formattedPhoneNumber: this.uiFormattedNumber,
        disableCallLogging: true
      },
      scrollStrategy: this.overlay.scrollStrategies.noop()
    });

    dialogRef.afterClosed().subscribe((result: SearchOrAssociatePatientModalComponentInterface) => {
      if (result && result.isActionSuccess == true && result.data?.length) {
        window.localStorage.setItem("isPatientAssociated", JSON.stringify(true));
        window.localStorage.setItem("continueAsUnknown", String(false));
        this.orgEntityId = result.data ? result.data[0].orgEntityId : "";
        this.initializeOutboundCallTask(this.orgEntityId);
        this.localService.resultSearchorAssociatePatient(result);
        this.uiFormattedNumberCopy = "";
      }
      else if (result && result.openCreatePatientModal) {
        const dialogRef = this.dialog.open(CreateNewPatientModalComponent, {
          // height: '600px',
          disableClose: false,
          data: { showBackToSearch: true },
          width: '1000px',
          scrollStrategy: this.overlay.scrollStrategies.noop()
          // data: { advanceSearchCriteria: this.advanceSearchObject.advanceSearchCriteria }
        });

        dialogRef.afterClosed().subscribe((result) => {
          if (result && result.goBackToSearch) {
            this.searchOrAssociateModal();
          }
          else if (result && result.associateWithNewPatient) {
            this.orgEntityId = result.associateWithNewPatient.data.orgEntityId;
            this.localService.setAssociatedPatientOrgId(result.associateWithNewPatient.data.orgEntityId);
            // let phoneNumber = this.dialedNumber.slice(2);
            this.initializeOutboundCallTask(this.orgEntityId);
          }
        });
      }
      else {
        this.orgEntityId = "";
        // let phoneNumber = this.dialedNumber.slice(2);

        this.initializeOutboundCallTask(this.orgEntityId);
        window.localStorage.setItem("continueAsUnknown", String(true));
        window.localStorage.setItem("isPatientAssociated", JSON.stringify(false));
      }
    });
  }

  ngOnInit(): void {
    this.isCallConnecting = false;
    this.isCallConnected = false;

    this.localService.isCurrentlyEnabled.subscribe((result: boolean) => {
      this.isTwilioCallsEnabled = result;
    });

    // gets status of provider for ex:- taking calls, making calls
    this.localService.receiveTwilioUserStatus.subscribe((result: string) => {
      this.userStatus = result;
      if (this.userStatus === "MAKING_CALLS") { this.isDialerVisible = true; }
    });

    // fetch permissions
    this.localService.receiveDialPadPermission.subscribe((hasPermission) => {
      this.dialPadPermission = hasPermission;
    });

    this.localService.receiveTwilioCallNotification.subscribe(({ reservation, callStatus, __this }) => {
      this.incomingCall = reservation;

      this.localService.getUserProviderId().subscribe((id) => {
        this.providerId = id;
      });

      // when patient or provider picks up call
      if (callStatus === "RESERVATION_ACCEPTED") {
        this.window["displayCreatePatient"] = true;
        this.localService.setInboxTabIndex(0);
        this.formattedPhoneNumber = '';
        this.muteStatus = false;
        this.totalAgentCount = 0;
        window.localStorage.setItem("isPatientAssociated", JSON.stringify(false));
        window.localStorage.setItem("clearCallsData", String(false));
        window.localStorage.setItem("completeBtnClicked", String(false));

        // if (reservation.task.attributes.isTransferTask || reservation.task.attributes.isConferenceTask) {
        //   if (reservation.task.attributes.orgEntityId) {
        //     window.localStorage.setItem("isPatientAssociated", JSON.stringify(true));
        //   }
        // }

        if (this.incomingCallAudio) {
          this.incomingCallAudio.currentTime = 0;
          this.incomingCallAudio.muted = true;
          this.incomingCallAudio.pause();
        }

        if (!this.window["isCallOutbound"]) {
          this.inboxService.setOpenConference(false);
        }

        if (!this.patientName || this.patientName == '' || this.patientName == null) { this.formattedPhoneNumber = this.incomingCall.task?.attributes?.from?.replace(/^(\+?1)?(\d{3})(\d{3})(\d{4})$/, "+1 $2-$3-$4"); }
        if (this.window["isCallOutbound"]) { this.router.navigate(['/focus/inbox']); }
        this.isCall = false;
        this.isCallConnected = true;
        this.setViewportBoundary();
        this.isCallConnecting = false;
        this.isCallPicked = true;
        this.localService.enableCallBtns(false);
        this.localService.callLeavedOrHangedup(callStatus);
      }

      // when call is ringing at provider's end
      else if (callStatus === "RESERVATION_CREATED") {
        window.localStorage.setItem("clearCallsData", String(false));
        if (!reservation.task.attributes.isOutboundConferenceTask && !reservation.task.attributes.isTransferTask && !reservation.task.attributes.isConferenceTask) {
          this.localService.setAssociatedPatientOrgId("");
        }
        this.localService.callLeavedOrHangedup(callStatus);
        this.profilePicture = "";
        this.patientPhoneNumber = reservation.task.attributes.from?.toString() || "";
        this.patientPhoneNumber = this.patientPhoneNumber?.trim()?.slice(-10);
        this.window["isCallOutbound"] = reservation.task.attributes.isOutboundConferenceTask;

        if (reservation.task.attributes.isTransferTask || reservation.task.attributes.isConferenceTask) {
          if (reservation.task.attributes.orgEntityId) {
            window.localStorage.setItem("isPatientAssociated", JSON.stringify(true));
            this.localService.setAssociatedPatientOrgId(reservation.task.attributes.orgEntityId);
          } else {
            this.localService.setAssociatedPatientOrgId("UNKNOWN_PATIENT");
          }
        }

        if (this.incomingCallAudio && !reservation.task.attributes.isOutboundConferenceTask) {
          this.incomingCallAudio.loop = true;
          this.incomingCallAudio.muted = false;
          this.incomingCallAudio.play();
        }

        window.focus();
        this.minimize = false;
        this.isCall = true;
        this.isCallConnecting = false;
        this.isCallPicked = false;
        if (!reservation.task.attributes.isOutboundConferenceTask) { this.isCallConnected = true; }
        this.setViewportBoundary();
        setTimeout(function () {
          $("#a").removeClass().addClass("fa fa-microphone-slash");
        });
        this.incomingCall = reservation;
        this.formattedPhoneNumber = this.incomingCall.task?.attributes?.from?.replace(/^(\+?1)?(\d{3})(\d{3})(\d{4})$/, "+1 $2-$3-$4");
        this.workerThis = Object.assign(this, __this);
        let rawData: reservation["task"] = this.workerThis.task;
        this.window["isCallOutbound"] = rawData?.attributes?.isOriginalCallOutbound ? true : false;
        var object: callLoggingObject = {
          orgEntityId: "",
          callSid: this.workerThis?.task?.attributes?.call_sid ? this.workerThis.task.attributes.call_sid : "Outbound Dial",
          callStatus: 'CALL_RECEIVED',
          callDescription: 'Call Received',
          callRawData: JSON.stringify(rawData),
          phoneNumber: this.patientPhoneNumber,
          providerId: this.providerId,
          callFrom: this.workerThis?.task?.attributes?.from,
          callTo: this.workerThis?.task?.attributes?.to
        };

        this.sendTwilioCallActivityStatus = this.inboxService.sendTwilioCallActivityStatus(object).subscribe({
          next: (result: sendTwilioCallActivityStatus) => {
            if (result.orgEntityId) {
              this.orgEntityId = result.orgEntityId;
            }
          },
          error: (error: HttpErrorResponse) => { console.log("An Error Occurred", error); }
        })

        if (reservation.task.attributes.isOutboundConferenceTask) {
          this.connectIncomingCall(reservation);
        }
      }

      // when patient or provider disconnects from call
      else if (callStatus === "RESERVATION_ENDED") {
        this.muteStatus = false;
        this.holdStatus = false;
        this.associatedPatientName = "";
        this.isCallDisconnectOptionsVisible = false;
        this.localService.resultSearchorAssociatePatient({});
        let rawData: reservation["task"] = Object.assign({}, this.workerThis.task);
        let object: callLoggingObject = {
          orgEntityId: this.orgEntityId,
          callSid: this.workerThis?.task?.attributes?.call_sid ? this.workerThis.task.attributes.call_sid : "Outbound Dial",
          callStatus: "PATIENT_SIDE_CALL_ENDED",
          callDescription: "patient Side Call Ended",
          callRawData: JSON.stringify(rawData),
          providerId: this.providerId,
          callFrom: this.workerThis?.task?.attributes?.from,
          callTo: this.workerThis?.task?.attributes?.to
        }
        this.sendTwilioCallActivityStatus = this.inboxService.sendTwilioCallActivityStatus(object).subscribe();
        this.inboxService.setCallCompletedWorkerTask(JSON.stringify(rawData));
        window.localStorage.setItem("isPatientAssociated", JSON.stringify(false));
        window.localStorage.setItem("completeBtnClicked", JSON.stringify(false));
        window.localStorage.setItem("continueAsUnknown", String(false));
        window.localStorage.setItem("agentsInCall", "");
        window.localStorage.setItem("providerList", "");
        this.isCallConnected = false;
        this.incomingCall = {} as reservation;
        this.localService.enableCallBtns(true);
        this.patientInitials = "";
        this.localService.callLeavedOrHangedup(callStatus);
        this.removeViewportBoundary();
        if (Object.keys(this.validateOutboundCall).length) {
          this.validateOutboundCall.unsubscribe();
        }
        this.validateOutboundCall = {} as Subscription;
        if (Object.keys(this.outboundConferenceCall).length) {
          this.outboundConferenceCall.unsubscribe();
        }
        this.outboundConferenceCall = {} as Subscription;
        if (Object.keys(this.outboundCallTask).length) {
          this.outboundCallTask.unsubscribe();
        }
        this.outboundCallTask = {} as Subscription;
        if (Object.keys(this.sendTwilioCallActivityStatus).length) {
          this.sendTwilioCallActivityStatus.unsubscribe();
        }
        this.sendTwilioCallActivityStatus = {} as Subscription;
      }

      // if patient or provider doesn't pick call or disconnects before pick
      else if (callStatus === "RESERVATION_TIMEOUT" || callStatus === "RESERVATION_CANCELED") {
        this.localService.callLeavedOrHangedup(callStatus);
        this.removeViewportBoundary();
        this.isCallConnected = false;
        window.localStorage.setItem("completeBtnClicked", JSON.stringify(true));
        window.localStorage.setItem("clearCallsData", String(true));
        this.localService.enableCallBtns(true);
        if (this.incomingCallAudio) {
          this.incomingCallAudio.currentTime = 0;
          this.incomingCallAudio.muted = true;
          this.incomingCallAudio.pause();
        }

        if (callStatus === "RESERVATION_TIMEOUT") {
          if (this.window["windowTwilioWorkerInstance"]?.worker?.update) {
            this.window["windowTwilioWorkerInstance"].worker.update("ActivitySid", this.window["availableActivity"].id, function (error: TwilioError, worker: workerOfWorkerInstance) {
              if (error) {
                console.log(error.code);
                console.log(error.message);
              }
            });
          }
        }
      }
    });

    // get details of associated patient
    this.localService.currentAssociatedPatientDetail.subscribe((result: callerDetail) => {
      if (result) {
        if (result.patientName === "" || result.patientName === 'UNKNOWN_PATIENT' || result.patientName === 'Unknown Patient' || result.patientName === 'Unknown_Patient' || result.patientName === 'unknown patient') {
          this.associatedPatientName = '';
        }
        else { this.associatedPatientName = result?.patientName?.replaceAll("_", " "); }
        this.profilePicture = result.profilePictureUrl;
        this.patientInitials = result.patientInitials;
        this.orgEntityId = result.orgEntityId;
      }
    })

    // updates workers in call
    this.localService.participantObj.subscribe((data: participantObject[] | undefined) => {
      if (data && data.length > 1) {
        this.totalAgentCount = data.length;
      }
      else {
        this.totalAgentCount = 0;
        this.isCallDisconnectOptionsVisible = false;
      }
    }), (error: Error) => {
      console.error('Error Occurred', error);
    }

    // For checking if call is on hold or not
    this.localService.isCallOnHold.subscribe((result: boolean) => {
      if (this.isCallPicked) {                  // this check will stop angular from making api call unnecessarily
        let rawData: reservation["task"] = Object.assign({}, this.workerThis.task);
        var object: callLoggingObject = {
          orgEntityId: this.orgEntityId,
          callSid: this.workerThis?.task?.attributes?.call_sid ? this.workerThis.task.attributes.call_sid : "Outbound Dial",
          callStatus: result ? "CALL_HOLD" : "CALL_UNHOLDED",
          callDescription: result ? "Call On Hold" : "Call Unholded",
          callRawData: JSON.stringify(rawData),
          providerId: this.providerId,
          callFrom: this.workerThis?.task?.attributes?.from,
          callTo: this.workerThis?.task?.attributes?.to
        }

        const callObject: callObject = {
          conferenceId: this.incomingCall?.task?.attributes?.conference?.sid,
          callerId: this.incomingCall?.task?.attributes?.conference?.participants?.customer,
          isHold: result,
          twilioToNumber: this.incomingCall?.task?.attributes?.to
        };

        this.twilioWorkerService.callHoldStatus(callObject).then(() => {
          this.sendTwilioCallActivityStatus = this.inboxService.sendTwilioCallActivityStatus(object).subscribe();
        });
      }
    });

    this.localService.isCallOnHoldUI.subscribe((result: boolean) => {
      this.holdStatus = result;
    })

  }
}
