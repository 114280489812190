export interface callerDetailsObject {
    orgEntityId: string,
    medicalRecordNumber: string,
    patientPhoneNumber: string,
    patientPhoneNumberType?: string,
    patientEmail: string,
    clientName: string,
    patientAddress: string,
    patientName: string
    dateOfBirth: string,
    patientExternalId: string,
    guarantorName: string,
    guarantorEmail: string,
    guarantorPhoneNumber: string,
    psychographicSegment: string,
    profilePictureUrl: string,
    patientInitials: string
}
export interface patientDetailsResult {
    isActionSuccess: boolean,
    data: callerDetailsObject[]
}
export interface transactionHistory {
    type: string,
    invoiceId: string,
    transactionId: string,
    paidDate: string,
    medium: string,
    description: string,
    amountPaid: string,
}

export interface invoiceGenerated {
    externalId: string,
    diagnosisName: string,
    clientName: string,
    visitDateTime: string,
    locationAddress: string | null,
    visitId: string,
    payerPlan: string | null,
    invoiceDate: string,
    invoiceAmount: string,
    transactionHistory: transactionHistory[]
    paymentUrl: string,
    visitInvoiceId: string,
    patientOauthUserId: string
}

export interface paymentScheduled {
    externalId: string,
    scheduledAmount: string | null,
    scheduledDate: string | null,
    diagnosisName: string,
    clientName: string,
    visitDateTime: string,
    locationAddress: string | null,
    visitId: string,
    payerPlan: string | null,
    invoiceDate: string | null,
    transactionHistory: transactionHistory[]
}

export interface paymentPlanData {
    paymentStatus: string,
    installmentName: string,
    dueDate: string,
    isPaid: number,
    transactionId: string,
    installmentAmount: string,
    installmentPaidDate: string,
}
export interface paymentPlanActivated {
    invoiceId: string,
    externalId: string,
    invoiceAmount: string | null,
    invoiceDate: string,
    diagnosisName: string,
    clientName: string,
    visitDateTime: string,
    locationAddress: string | null,
    visitId: string,
    payerPlan: string | null,
    paymentPlanData: paymentPlanData[],
    transactionHistory: transactionHistory[]
}

export interface paymentFailed {
    externalId: string,
    invoiceAmount: string,
    invoiceDate: string | null,
    transactionId: string | null,
    reason: string,
    scheduledDate: string,
    diagnosisName: string,
    clientName: string,
    visitDateTime: string,
    locationAddress: string | null,
    visitId: string,
    payerPlan: string | null,
    lastFourDigit: string,
    transactionHistory: transactionHistory[],
    visitInvoiceId: string
}

export interface paymentSuccessfull {
    externalId: string,
    totalAmount: string,
    transactionDateTime: string,
    transactionId: string,
    diagnosisName: string,
    clientName: string,
    visitDateTime: string,
    locationAddress: string | null,
    visitId: string,
    payerPlan: string | null,
    invoiceDate: string,
    transactionHistory: transactionHistory[]
}
export interface outstandingBalanceResponse {
    isActionSuccess: boolean;
    data: string;
}
export interface paymentSuccessfullResponse {
    isActionSuccess: boolean;
    data: paymentSuccessfull[];
}

export interface invoiceGeneratedResponse {
    isActionSuccess: boolean;
    data: invoiceGenerated[];
}
export interface paymentScheduledResponse {
    isActionSuccess: boolean;
    data: paymentScheduled[];
}
export interface paymentPlanActivatedResponse {
    isActionSuccess: boolean;
    data: paymentPlanActivated[];
}
export interface paymentFailedResponse {
    isActionSuccess: boolean;
    data: paymentFailed[];
}
export interface providerSideConsolidatedResponse{
    isActionSuccess: boolean;
    data: boolean;
}
export interface section {
    type: number;
    title: string;
    sections: section[];
    description: string;
    sectionIcon: string;
    sectionIconUrl: string;
    isOpen?: boolean;
    id?: string;
}

export interface guidanceInterface {
    isActionSuccess: boolean;
    data: {
        clientId: string;
        guidance: section[];
        isDefault: boolean;
        clientName: string;
    };
}
export interface psychographicGuidanceIconsInterface {
    data: iconData[];
}
export interface iconData {
    id: string;
}
export interface psychographicGuidanceInterface {
    data: data;
}
export interface data {
    records: records;
}
export interface records {
    id: string;
    guidance: section[];
    createdDateTimeUtc: string
    lastUpdatedDateTimeUtc: string
    deletedDateTimeUtc: any
    businessUnitId: string
    createdById: string
    lastUpdatedById: string
}
export interface classifications {
    value: string;
    valueLongDescription: string;
}
export interface activityLogChatinterface {
    data : checkLatestActivityLogs[],
    totalCount : number
}

export interface checkLatestActivityLogs {
    isDelivered: number;
    Classification: classifications[];
    messageType: string;
    communicationMode: string;
    sourceType: string;
    message: string;
    fromPhoneNumberOrEmailAddress: string | null;
    toPhoneNumberOrEmailAddress: string | null;
    subject: string | null;
    createdDateTimeUtc: string;
    lastUpdatedDateTimeUtc: string;
    username: string;
    initials: string;
    profilePictureUrl: string;
    id: string;
    patientName: string;
    attachments: string[] | null;
    createdByName: string;
    intervalTime: string | null;
    intervalType: string | null;
    snoozeIntervalEndDate: string | null;
    timeAgo?: string
}

export interface checkLatestActivityLogsResult {
    data: checkLatestActivityLogs[];
    isActionSuccess: boolean;
    orgEntityId: string;
    status: number;
    latestSnooze:any;
}