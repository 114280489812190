<div class="modal-header">
    <div class="row w-100 mx-0">
        <div class="col-sm-10 px-0 d-flex align-items-center">
            <img src="../../../../../assets/images/file-icon.svg" style="height: 28px;width: 28px;margin-right: 5px;" alt="" loading="lazy">
            <span>
                <h2 class="font-thin mb-0">Confirmation</h2>
            </span>
        </div>
        <div class="col-sm-2 d-flex justify-content-end align-items-center">
            <button class="d-flex justify-content-center align-items-center pl-0"
                style="border-radius: 50%; border:1px solid #58666e;color:#58666e !important;background-color: #fff;min-height: 25px; min-width:25px;cursor: pointer;">
                <i class="fa fa-close" (click)="cancel(false)"></i>
            </button>
        </div>
    </div>
</div>
<div class="modal-body" style="max-height:calc(90vh - 152px); overflow:auto; text-align: center; ">
    <img src="../../../../../assets/images/Group242890.svg" style="height: 80px; width: 80px;margin-bottom: 10px;" alt="" loading="lazy">
    <h1 style="color:#1c4650;margin-bottom: 3px;padding: 0 50px;">Unsaved changes will be lost. Are you sure you want to continue?</h1>
</div>
<div class="modal-footer" style="justify-content: space-between;">
    <button id="stayOnPageBtn" class="btn btn-default" style="font-size: 12px;background-color: #1c4650;color:#fff!important;" (click)="cancel(false)">Stay on Page</button>
    <button id="leavePageBtn" class="btn btn-default" style="font-size: 12px; border: 1px solid #dedede;" (click)="cancel(true)" >Leave Page</button>
</div>