<div class="modal-header" style="padding: 20px 30px;;display: block;  padding-bottom: 0px;">
  <div class="row">
    <div class="col-xs-10 col-sm-10 col-md-10">
      <h3 class="modal-title">
        <img src="../../assets/images/Group 240347.svg" style="width: 5%;padding-left: 5px;;text-align: center;"
          alt="">&nbsp;&nbsp;
        <span class="h3 font-thin" style="color: #58666E; font-size: 22px;">Create Patient</span>

      </h3>
    </div>
    <div class="col-xs-2 col-sm-2 col-md-2">
      <button style="border: none;outline: none;float: right;background: transparent" (click)="closeModal()"> <img
          src="../../assets/images/close.svg" style=" color: white !important;
                border-radius: 4px;
                width: 28px;
                margin-top: 5px;" />
      </button>
    </div>
  </div>
</div>
<div class="modal-body" style="padding: 15px 30px;;display: block;">
  <div class="row" style="padding-bottom: 25px;padding-top: 20px;">
    <div class="col-md-4">
      <p>External Id*</p>
      <input tabindex="1" class="input-wrapper" type="text" class="input" placeholder="External Id"
        (keydown.space)="$event.preventDefault()" [(ngModel)]=" patientInformationObject.patientInfo.externalId"
        (paste)="removeAllSpace()">
    </div>
    <div class="col-md-4">
      <p>First Name*</p>
      <input tabindex="1" class="input-wrapper" type="text" class="input" placeholder="First Name"
        [(ngModel)]=" patientInformationObject.patientInfo.firstName" (keypress)="keyPressAlphaNumeric($event)"
        disallowSpaces>
    </div>
    <div class="col-md-4">
      <p>Last Name*</p>
      <input tabindex="1" class="input-wrapper" type="text" class="input" placeholder="Last Name"
        (keypress)="keyPressAlphaNumeric($event)" [(ngModel)]=" patientInformationObject.patientInfo.lastName"
        disallowSpaces>
    </div>
  </div>
  <div class="row" style="padding-bottom: 25px;">
    <div class="col-md-4">
      <p>Phone</p>
      <input tabindex="1" class="input-wrapper" type="text" class="input" placeholder="Phone" (keyup)="formatNumber()"
        (paste)="onPaste($event)" (keypress)="checkNumber($event)"
        [(ngModel)]=" patientInformationObject.patientInfo.phoneNumbers[0].phoneNumber" maxlength="15">
    </div>
    <div class="col-md-4">
      <p>Email</p>
      <input tabindex="1" class="input-wrapper" type="text" class="input" placeholder="Email"
        [(ngModel)]=" patientInformationObject.patientInfo.emailAddresses[0].email" disallowSpaces>
    </div>
    <div class="col-md-4 gender">
      <p>Gender*</p>

      <mat-form-field appearance="fill">

        <input type="text" placeholder="Gender" matInput [matAutocomplete]="gender"
          style="caret-color: transparent; border: none; height: unset;"
          [(ngModel)]="patientInformationObject.patientInfo.birthGender" onkeydown="return false;">
        <i class="fa fa-caret-down align-arrow-right"></i>
        <mat-autocomplete #gender="matAutocomplete">
          <mat-option *ngFor="let compOperator of genderArr" [value]="compOperator">
            {{compOperator}}
          </mat-option>
        </mat-autocomplete>

        <!-- <mat-select placeholder="Gender" tabindex="1" [(ngModel)]="patientInformationObject.patientInfo.birthGender">
          <mat-option *ngFor="let compOperator of gender" [value]="compOperator" style="width:291px;">
            {{compOperator}}
          </mat-option>
        </mat-select> -->
      </mat-form-field>
    </div>
  </div>
  <div class="row" style="padding-bottom: 15px;">
    <div class="col-md-4">
      <p>DOB*</p>
      <div class="form-group year" style="margin-bottom: 20px;">
        <div class="input-group col-xs-12" style="display:flex;width: 100%;">
          <mat-form-field appearance="fill">

            <input type="text" class="autocomplete-input" placeholder="Month" matInput [matAutocomplete]="month"
              style="caret-color: transparent; border: none; height: unset;" [(ngModel)]="dateOfBirthObject.dobMonth"
              onkeydown="return false;" (ngModelChange)="dateOfBirthObject.dobDay = null;">
            <i class="fa fa-caret-down align-arrow-right"></i>
            <mat-autocomplete #month="matAutocomplete" class="min-width-unset" [displayWith]="monthName.bind(this)">
              <mat-option *ngFor="let item of months" [value]="item.id" style="font-size: 13px;" (click)="setDays()">
                {{item.displayName}}
              </mat-option>
            </mat-autocomplete>

            <!-- <mat-select placeholder="Month" tabindex="1" [(ngModel)]="dateOfBirthObject.dobMonth"
              (selectionChange)="setDays()" style="font-size: 13px;color:red">
              <mat-option *ngFor="let item of months" [value]=" item.id" style="width:92px">
                {{item.displayName}}
              </mat-option>
            </mat-select> -->
          </mat-form-field>
          <mat-form-field appearance="fill">
            <input type="text" placeholder="Day" matInput [matAutocomplete]="day"
              style="caret-color: transparent; border: none; height: unset;" [(ngModel)]="dateOfBirthObject.dobDay"
              onkeydown="return false;">
            <i class="fa fa-caret-down align-arrow-right"></i>
            <mat-autocomplete #day="matAutocomplete" class="min-width-unset">
              <mat-option *ngFor="let day of days" [value]="day" style="font-size: 13px;">
                {{day}}
              </mat-option>
            </mat-autocomplete>

            <!-- <mat-select placeholder="Day" tabindex="1" [(ngModel)]="dateOfBirthObject.dobDay" style="font-size: 13px; ">
              <mat-option *ngFor="let day of days" [value]="day" style="width:97px !important">
                {{day}}
              </mat-option>
            </mat-select> -->
          </mat-form-field>
          <!-- <select style="width:33.33%;height: 40px;font-size: 13px;" tabindex="0" class="form-control virtual-care text-center"
                      [(ngModel)]=" dateOfBirthObject.dobMonth" (change)="setDays()">
                      <option value="0" disabled selected>Month</option>
                      <option *ngFor="let item of months" [ngValue]=" item.id">{{item.displayName}}
                      </option>
                    </select> -->
          <!-- <select style="width: 33.33%;height: 40px;font-size: 13px;"tabindex="0" class="form-control virtual-care text-center"
                    [(ngModel)]=" dateOfBirthObject.dobDay" >
                    <option value="0" disabled selected>Day</option>
                    <option *ngFor="let day of days" [ngValue]=" day">{{day}}
                    </option>
                  </select> -->
          <input style="width:30%;font-size: 13px;" id="year" type="text" tabindex="1" maxlength="4"
            class="form-control virtual-care text-center" placeholder="Year" [(ngModel)]="dateOfBirthObject.dobYear"
            (keypress)="checkNumber($event)" />
        </div>
      </div>
    </div>
  </div>
</div>
<div style="padding:0px 30px">
  <div class="modal-footer" style="padding: 15px 0px;;display: block; border-top: 1px dashed #D9D9D6;
;">
    <div class="row" style="padding:10px;padding-right: 0px;margin-right: 0px;">
      <div class="col-md-12" style="display: flex;justify-content: end;padding-right: 0px;">
        <button *ngIf="showBackToSearchBtn" style="padding: 6px 30px; margin-right:10px; border: 1px solid #dedede"
          class="btn btn-default" (click)="backToSearch()">Back to Search</button>
        <button style=" padding: 10px 25px;
    border: none;
    background: #1FBD75;
    color: white;" (click)="createPatientInformation()">Save Patient</button>
      </div>
    </div>
  </div>
</div>